<template>
  <div class="card">
    <Breadcrumb :model="items"/>
    <div class="p-mt-3">
      <DataTable ref="dt" :value="messageSee" v-model:selection="selectedProducts" dataKey="id"
                 :paginator="true" :rows="10" :filters="filters"
                 paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                 :rowsPerPageOptions="[5,10,25]"
                 currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计 {totalRecords} 记录">
        <template #header>
          <div style="display: flex">
            <InputText class="p-mr-2" v-model="title" placeholder="关键字搜索"/>
            <!--            <Dropdown-->
            <!--                @change="query" class="p-mr-2"-->
            <!--                v-model="messageType" :options="messageTypeList" optionLabel="messageType"-->
            <!--                option-value="messageType"-->
            <!--                placeholder="消息类型" :show-clear="true"></Dropdown>-->
            <Calendar
                @clear-click="showUnRead"  class="p-mr-2"
                onfocus=this.blur() :showIcon="true" :showButtonBar="true" v-model="time"
                placeholder="选择发布时间" dateFormat="yy-mm-dd"></Calendar>
            <Button class="p-ml-2" @click="showUnRead">查询</Button>
          </div>
        </template>
        <Column field="title" header="消息名称" :sortable="false"></Column>
        <Column field="brief" header="消息简介" :sortable="false"></Column>
        <Column field="allUser" header="消息类型" :sortable="false">
          <template #body="a">
            {{ a.data.allUser == 1 ? '全员消息' : '部分消息' }}
          </template>
        </Column>
        <Column field="industry" header="所属行业" :sortable="false"></Column>
        <Column field="time" header="时间" :sortable="true">
          <template #body="a">
            {{ a.data ? new Date(a.data.time).toLocaleString() : '' }}
          </template>
        </Column>
        <Column field="operation" header="状态" :sortable="false">
          <template #body="slotProps">
            <div>
              <label v-if="!slotProps.data.look">全员消息</label>
              <label v-if="slotProps.data.look==0">未查看</label>
              <label v-if="slotProps.data.look==1">已查看</label>
            </div>
          </template>
        </Column>
        <Column field="operation" header="操作" :exportable="false">
          <template #body="slotProps">
            <Button @click="goto(slotProps.data.id)">查看</Button>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>

  <Toast/>
</template>

<script>
export default {
  mounted() {
    this.showUnRead()
    //this.showType()

  },
  data() {
    return {
      selectedProducts: null,
      title: null,
      messageType: null,
      time: null,
      messageTypeList: [],
      messageSee: [],
      items: [
        {label: '消息管理', to: '/'},
        {label: '查看消息'},
      ],
    }
  },
  methods: {

    reset() {
      this.title = null;
      this.messageType = null;
      this.time = "";
    },
    showType() {
      this.$http.get('/message/managementList').then((res) => {
        console.log(res.data)
        this.messageTypeList = res.data
      })
    },
    goto(id) {
      this.$http.post('/send/look?id=' + id).then(() => {
        this.$router.push('/messageDetail?id=' + id)
      })
    },
    showUnRead() {
      this.$http.get('/send/notice',{
        params:{
          text:this.title,
          time:this.time
        }
      }).then((res) => {
        this.messageSee = res.data
        console.log(this.messageSee)
      })
    },
  }
}
</script>

<style scoped>

</style>